import React, { useEffect, useState } from "react";
import { Link, graphql, navigate } from "gatsby";
import Bio from "../components/bio";
import Layout from "../components/layout";
import SEO from "../components/seo";
import Poster from "../components/poster";
import "../components/blog.scss";

type Props = {
  data: any;
  pageContext: any;
  location: any;
};

function BlogPostTemplate({ location, data, pageContext }: Props) {
  const post = data.markdownRemark;
  const siteTitle = data.site.siteMetadata.title;
  const thumbnail =
    data.markdownRemark.frontmatter.thumbnail &&
    data.markdownRemark.frontmatter.thumbnail.childImageSharp.fixed.src;
  const [isSlideIn, setIsSlideIn] = useState(false);
  const { previous, next } = pageContext;

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsSlideIn(true);
    }, 500);
    return () => {
      if (timer) {
        clearTimeout(timer);
      }
    };
  }, []);

  const poster = (post.frontmatter && post.frontmatter.poster) || data.poster;

  const backToWriting = e => {
    e.preventDefault();
    setIsSlideIn(false);
    setTimeout(() => {
      navigate("/writing");
    }, 1000);
  };

  return (
    <Layout location={location} title={siteTitle}>
      <SEO
        title={post.frontmatter.title}
        description={post.frontmatter.description || post.excerpt}
        thumbnail={thumbnail}
      />

      <Link
        to="/writing"
        className={`blog-back ${isSlideIn ? "blog-back--visible" : ""}`}
        aria-label="Back to writing"
        onClick={backToWriting}
      >
        ← writing
      </Link>
      <Poster
        isFixed
        index={-1}
        node={{
          frontmatter: { poster },
          fields: { slug: "" }
        }}
      />
      <div className="blog">
        <h1 className="blog-title">
          <span className="blog-text">{post.frontmatter.title}</span>
        </h1>
        <div className="blog-item ">
          <div className="blog-item-text">
            <p className="blog-post-date">{post.frontmatter.date}</p>
            <div dangerouslySetInnerHTML={{ __html: post.html }} />
          </div>
          <div className="blog-footer">
            <Bio />
            <ul className="prevnext">
              <li>
                {previous && (
                  <Link to={`/writing/${previous.fields.slug}`} rel="prev">
                    ← {previous.frontmatter.title}
                  </Link>
                )}
              </li>
              <li>
                {next && (
                  <Link to={`/writing/${next.fields.slug}`} rel="next">
                    {next.frontmatter.title} →
                  </Link>
                )}
              </li>
            </ul>
          </div>
        </div>
      </div>
    </Layout>
  );
}

export default BlogPostTemplate;

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    site {
      siteMetadata {
        title
        author
      }
    }
    poster: file(relativePath: { eq: "sky.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 3840) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      excerpt(pruneLength: 160)
      html
      frontmatter {
        title
        date(formatString: "MMMM DD, YYYY")
        description
        poster {
          childImageSharp {
            fluid(maxWidth: 3840) {
              ...GatsbyImageSharpFluid_tracedSVG
              presentationWidth
            }
          }
        }
        thumbnail: poster {
          childImageSharp {
            fixed(width: 1024) {
              ...GatsbyImageSharpFixed_noBase64
            }
          }
        }
      }
    }
  }
`;
